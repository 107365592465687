import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import urls from 'constants/urls';

import AnchorLink from 'components/AnchorLink';
import PendingOrderSummaryV1 from 'components/PendingOrders/PendingOrderSummaryV1';
import styles from 'components/NextOrder/NextOrder.scss';
import { getFeatureFlags } from 'utils/feature-flags';
import OrderCard from '../PendingOrders/OrderCard/OrderCard';

const NextOrder = ({ analyticsTags, cqResponsiveClasses, hidden, pendingOrderId, isLoaded }) => {
  const { checkout_wismo: checkoutWismoEnabled } = getFeatureFlags();

  return isLoaded && pendingOrderId && !hidden ? (
    <article
      className={classNames(cqResponsiveClasses, styles.nextOrder)}
      data-test="next-order"
      {...analyticsTags}
    >
      <h2 className={styles.heading}>Your next order</h2>
      {checkoutWismoEnabled ? (
        <OrderCard orderId={pendingOrderId} />
      ) : (
        <PendingOrderSummaryV1 orderId={pendingOrderId} />
      )}
      <AnchorLink className={styles.viewAll} to={urls.myOrdersPage}>
        View all orders
      </AnchorLink>
    </article>
  ) : null;
};

NextOrder.displayName = 'NextOrder';

NextOrder.propTypes = {
  analyticsTags: PropTypes.shape({}),
  cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.bool,
  pendingOrderId: PropTypes.string,
  isLoaded: PropTypes.bool,
};

NextOrder.defaultProps = {
  analyticsTags: undefined,
  cqResponsiveClasses: undefined,
  hidden: false,
  pendingOrderId: null,
  isLoaded: false,
};

export default NextOrder;
